import React, { useEffect } from "react";
import ReactHtmlParse from "react-html-parser";
import { graphql, navigate } from "gatsby";
import styled from "styled-components";
import Layout from "@components/Layout";
import * as SH from "@shared";
import * as theme from "@theme";
import { Container, Row, Col, Form } from "react-bootstrap";
import * as C from "@components/VacanciesPage";

const Article: React.FC<TData> = ({ data }) => {
  const vacancies = data.strapiVacancies;

  useEffect(() => {
    vacancies.title === "null" && navigate("/careers/404/");
  }, []);

  return (
    <Layout seo={vacancies.seo}>
      <SH.HeroSection
        bgHero={vacancies.hero}
        title={vacancies.hero?.title || "current openings"}
        descriptionBig={vacancies.title}
      />
      <SH.Wrapper.Primary>
        <Container>
          <Row>
            <StyledCol lg="9" className="offset-lg-2">
              <SH.Text.MarkdownTextBody className="py-5">
                {ReactHtmlParse(vacancies.introduction)}
              </SH.Text.MarkdownTextBody>
              <SH.Text.Header3 colorComponent="darkblue">
                Responsibilities
              </SH.Text.Header3>
              <SH.Text.MarkdownTextBody>
                {ReactHtmlParse(vacancies.responsibilities)}
              </SH.Text.MarkdownTextBody>
              <SH.Text.Header3 colorComponent="darkblue">
                Requirements
              </SH.Text.Header3>
              <SH.Text.MarkdownTextBody>
                {ReactHtmlParse(vacancies.requirements)}
              </SH.Text.MarkdownTextBody>
              <SH.Text.Header3 colorComponent="darkblue">
                Benefits and Perks
              </SH.Text.Header3>
              <SH.Text.MarkdownTextBody>
                {ReactHtmlParse(vacancies.benefits)}
              </SH.Text.MarkdownTextBody>
            </StyledCol>
          </Row>

          <C.VacanciesForm title={vacancies.title} />
        </Container>
      </SH.Wrapper.Primary>
    </Layout>
  );
};

export default Article;

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiVacancies(slug: { eq: $slug }) {
      title
      slug
      introduction
      id
      benefits
      responsibilities
      requirements
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicUrl
          }
        }
      }
      hero {
        title
        bgMobile {
          localFile {
            publicUrl
          }
        }
        bgDesktop {
          localFile {
            publicUrl
          }
        }
      }
    }
  }
`;

type TData = {
  data: {
    strapiVacancies: {
      title: string;
      slug: string;
      introduction: string;
      id: string;
      benefits: string;
      responsibilities: string;
      requirements: string;
      seo: {
        metaTitle: string;
        metaDescription: string;
        shareImage: {
          localFile: {
            publicUrl: string;
          };
        };
      };
      hero: {
        title: string;
        bgMobile: {
          localFile: {
            publicUrl: string;
          };
        };
        bgDesktop: {
          localFile: {
            publicUrl: string;
          };
        };
      };
    };
  };
};

const StyledCol = styled(Col)`
  margin-bottom: 100px;
  @media ${theme.breakpoints.desktop} {
    margin-bottom: 150px;
  }
`;
