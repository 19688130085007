import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import ReCaptcha from "react-google-recaptcha";
import { graphql } from "gatsby";
import styled from "styled-components";
import * as SH from "@shared";
import * as theme from "@theme";
import { Container, Row, Col, Form } from "react-bootstrap";
import { preFetch } from "@utils";
const recaptchaAPIKey = `${process.env.GATSBY_GOOGLE_CAPTCHA_SITEKEY}`;
const MAILER_API = `${process.env.GATSBY_MAILER_API}`;

const validateFile = (value: [{ size: number; type: string }]) => {
  return (
    value && value[0].size < 6000000 && value[0].type === "application/pdf"
  );
};

export const VacanciesForm: React.FC<TProps> = ({ title }) => {
  const [fileName, setFileName] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    watch,
    getValues,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [isError, setIsError] = useState(false);
  const formElemRef = useRef<HTMLFormElement>(null);
  const captchaRef = useRef<ReCaptcha>(null);
  const [file, setFile] = useState("");
  const [fileProps, setFileProps] = useState<TFileProps>({});

  useEffect(() => {
    register({ name: "captchaResponse" });
  }, []);

  useEffect(() => {
    if (!!file.length && fileProps && fileProps?.name) {
      sendMail();
    }
  }, [file, fileProps]);

  const sendMail = async () => {
    if (captchaRef && captchaRef.current) {
      captchaRef.current.reset();
      let token: string | null = "";
      try {
        token = await captchaRef.current.executeAsync();
        await setValue("captchaResponse", token);
      } catch {
        setIsError(true);
        setIsLoading(false);
      }

      const dataForm = watch();

      fetch(MAILER_API, {
        body: JSON.stringify({
          formType: "jobs",
          position: title,
          ...dataForm,
          file,
          fileProps: fileProps,
        }),
        method: "POST",
      })
        .then((response) => {
          if (response.ok) {
            setIsSend(true);
          } else {
            return Promise.reject(`Http error: ${response.status}`);
          }
        })
        .catch(() => setIsError(true))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  };
  const preSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    preFetch();
    handleSubmit(onSubmit)(e);
  };
  const onSubmit = async (data: TData) => {
    setIsLoading(true);
    const fileReader: FileReader = new FileReader();

    await fileReader.readAsDataURL(data.file[0]);

    fileReader.onload = (event: Event) => {
      if (data.file[0].type === "application/pdf") {
        setFile(`${fileReader.result}`);

        setFileProps({
          name: data.file[0].name,
          size: data.file[0].size,
          type: data.file[0].type,
        });
      }
    };
  };

  const onLoadFile = (e) => {
    if (e.target.files[0]) {
      setFileName(e.target.files[0].name);
    } else {
      setFileName("");
    }
  };

  return (
    <form ref={formElemRef} onSubmit={preSubmit}>
      <SH.Wrapper.Box colorComponent="whitegray">
        <Row className="justify-content-center align-items-center">
          <Col xs="10">
            <Row className="mt-lg-5 justify-content-between row">
              <Col
                className="flex-column-reverse d-flex flex-column overflow-hidden  px-0"
                lg="5"
              >
                <SH.Input.BaseInput
                  placeholder="email address"
                  name="email"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  })}
                />
                <SH.Input.Label htmlFor="email" isError={errors.email}>
                  {" "}
                  email
                </SH.Input.Label>
              </Col>
              <Col
                lg={5}
                className="d-flex flex-column justify-content-center text-center mt-5 mt-lg-0 px-0"
              >
                <SH.Input.LabelFileText>your cv</SH.Input.LabelFileText>
                <SH.Input.BaseInput
                  name="file"
                  type="file"
                  id="file"
                  accept="application/pdf"
                  onChange={(e) => e && onLoadFile(e)}
                  ref={register({
                    validate: (value) => validateFile(value),
                    required: true,
                    min: 1,
                    max: 1,
                  })}
                  className="pb-0 pl-0 order-2"
                />
                <SH.Input.LabelFile
                  className="order-1"
                  isError={errors.email}
                  htmlFor="file"
                >
                  <SH.Button.BaseUpload>Browse</SH.Button.BaseUpload>

                  {fileName || "please add your CV in PDF format"}
                </SH.Input.LabelFile>
                {errors.file && (
                  <Form.Text className="text-danger text-left">
                    Incorrect CV file (only pdf, max 5MB)
                  </Form.Text>
                )}
              </Col>
              <WrapperSubmit
                lg="12"
                className="d-flex justify-content-center pb-3"
              >
                {!isLoading && !isSend && (
                  <>
                    {isError ? (
                      <SH.Text.SubTitle3 colorComponent="blueblack">
                        Sorry, something went wrong. <br /> Please use the
                        chatbox.
                      </SH.Text.SubTitle3>
                    ) : (
                      <SH.Button.Base type="submit">apply </SH.Button.Base>
                    )}
                  </>
                )}
                {isLoading && !isError && <SH.Loader />}
                {isSend && !isError && <SH.DoneBoxAnimated />}
              </WrapperSubmit>
            </Row>
          </Col>
        </Row>
      </SH.Wrapper.Box>
      <Row className={"justify-content-center"}>
        <Col lg="10">
          <SH.Text.Overline>
            Zgoda na przetwarzanie danych osobowych w przyszłych procesach
            rekrutacji <br />
            <br />
            CodersPeak Tomasz Brunarski, z siedzibą w Krakowie, przy ul.
            Poznańskiej 10/36, kod pocztowy 30-126, NIP. 677 222 66 06 (zwaną
            dalej: „Administratorem”), w celach rekrutacji prowadzonej przez
            Administratora w okresie 2 lat od otrzymania moich danych lub do jej
            odwołania. Dane mogą zostać przekazane podmiotom przetwarzającym
            dane osobowe na zlecenie Administratora.
          </SH.Text.Overline>
          <SH.Text.Overline>
            <SH.Input.LabelClause htmlFor="clause" isError={errors.clause}>
              <input
                onChange={() => {
                  const value = getValues("clause");
                  setValue("clauseeng", value);
                }}
                ref={register({
                  required: true,
                })}
                type="checkbox"
                name="clause"
                id="clause"
              />
              <span className="checkmark" />
              Zostałem(-am) poinformowany(-a) przez Administratora o możliwości
              wycofania zgody w dowolnym momencie, jak również że zgoda ma
              charakter dobrowolny, a jej niewyrażenie skutkować będzie nie
              wzięciem mnie pod uwagę w przyszłych procesach rekrutacyjnych.
              Jednocześnie oświadczam, że zostałem/-am pouczony/-a przez
              administratora danych o prawach przysługujących mi w związku z
              udzieloną zgodą, w tym o prawie do:
              <br />
              <br />- dostępu do danych, do otrzymania ich kopii, sprostowania,
              usunięcia lub żądania ograniczenia ich przetwarzania, jak również
              o prawie do przenoszenia danych i prawie do „bycia zapomnianym”.
              Wycofać zgodę oraz skorzystać z pozostałych uprawnień można
              poprzez wysłanie wiadomości e-mail na adres
              dominika@coderspeak.com, - wniesienia skargi do organu
              nadzorczego.
            </SH.Input.LabelClause>
          </SH.Text.Overline>
        </Col>
        <Col lg="10">
          <SH.Text.Overline>
            Consent to the processing of personal data in the future recruitment
            processes
            <br />
            <br />I consent to the processing of my personal data contained in
            the documents and forms sent by me and collected during the
            recruitment procedure by CodersPeak Tomasz Brunarski, registred in
            Krakow, at Poznańska 10/136, postal code 30-012, TaxID 677 222 66 06
            (hereinafter referred to as "the Administrator"), for recruitment
            purposes carried out by the Administrator within 2 years of receipt
            of my data or until its revocation. The data may be transferred to
            entities that process personal data at the request of the
            Administrator.
          </SH.Text.Overline>
          <SH.Text.Overline>
            <SH.Input.LabelClause htmlFor="clauseeng" isError={errors.clause}>
              <input
                ref={register({
                  required: true,
                })}
                onChange={() => {
                  const value = getValues("clauseeng");
                  setValue("clause", value);
                }}
                type="checkbox"
                name="clauseeng"
                id="clauseeng"
              />
              <span className="checkmark" />
              I have been informed by the Administrator about the possibility of
              withdrawing consent at any time, as well as that the consent is
              voluntary, and its failure will result in not taking me into
              account in future recruitment processes. I declare that I have
              been instructed by the data controller about my rights in relation
              to the granted consent, including the right to:
              <br />
              <br />- access to data, to receive copies, rectification, deletion
              or demand to limit their processing, as well as the right to
              transfer data and the right to "be forgotten". Withdraw your
              consent and use the remaining rights by sending an e-mail to the
              address dominika@coderspeak.com, - lodging a complaint to the
              CodersPeak Tomasz Brunarski
            </SH.Input.LabelClause>
          </SH.Text.Overline>
        </Col>
      </Row>
      <ContainerCaptcha>
        <ReCaptcha
          size="invisible"
          badge="inline"
          ref={captchaRef}
          sitekey={recaptchaAPIKey}
        />
      </ContainerCaptcha>
    </form>
  );
};

type TProps = {
  title: string;
};
type TFileProps = {
  name: string;
  size: string;
  type: "application/pdf";
};

type TData = {
  file: TFileProps[];
  email: string;
  clause: string;
  clauseeng: string;
};

const ContainerCaptcha = styled.div`
  position: fixed;
  width: auto;
  left: -180px;
  bottom: 0;
`;

const WrapperSubmit = styled(Col)`
  min-height: 75px;
  margin-top: 37px;
  @media ${theme.breakpoints.desktop} {
    margin-top: 57px;
  }
`;
